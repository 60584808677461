export default function () {

    const $route = useRoute();
    const functions = useUtilityFunctions();

    function route (lesson, context, courseSlug) {
        if (!lesson.lesson_route) lesson.lesson_route = {name: 'videos-lessonSlug', params: {lessonSlug: lesson.lesson_slug}};
        const route = functions.cloneObject(lesson.lesson_route);
        route.query = {
            context: $route.query.context || context || undefined, // Preserves any current context
            courseSlug: $route.params.courseSlug || $route.query.courseSlug || courseSlug || undefined,
            pathwaySlug: $route.query.pathwaySlug || undefined,
        };
        return route;
    }

    function routeSearch (lesson, searchQuery) {
        const route = functions.cloneObject(lesson.lesson_route);
        if (searchQuery) {
            route.query = {
                context: 'header-search',
                q: searchQuery,
            };
            return route;
        }
        route.query = {
            context: 'videos-search',
            q: $route.query.q || undefined, // Searched text
            order: $route.query.order || undefined, // Searched sort order
            tags: $route.query.tags || undefined, // Searched tags
            ui: $route.query.ui || undefined, // Collection Mode?
            title: $route.query.title || undefined, // Custom title

            lesson_artist: $route.query.lesson_artist || undefined,
            lesson_category: $route.query.lesson_category || undefined,
            lesson_decade: $route.query.lesson_decade || undefined,
            lesson_difficulty: $route.query.lesson_difficulty || undefined,
            lesson_genre: $route.query.lesson_genre || undefined,
            lesson_grade: $route.query.lesson_grade || undefined,
            lesson_instrument: $route.query.lesson_instrument || undefined,
            lesson_skill: $route.query.lesson_skill || undefined,
            lesson_type: $route.query.lesson_type || undefined,
        };
        return route;
    }

    function routeNext (lesson, nextCourseSlug) {
        let nextLesson = lesson?.lesson_course?.course_next_lesson || undefined;
        if (!nextLesson && nextCourseSlug) {
            return {
                name: 'courses-courseSlug',
                params: {
                    courseSlug: nextCourseSlug,
                },
                query: {
                    context: $route.query.context || 'course',
                    pathwaySlug: $route.query.pathwaySlug || undefined,
                },
            };
        }
        if (!nextLesson) return null;
        return {
            name: 'videos-lessonSlug',
            params: {
                lessonSlug: nextLesson.lesson_slug
            },
            query: {
                context: $route.query.context || 'course',
                pathwaySlug: $route.query.pathwaySlug || undefined,
                courseSlug: $route.query.courseSlug || lesson?.lesson_course?.course_slug,
            },
        };
    }

    function routePrevious (lesson) {
        let previousLesson = lesson?.lesson_course?.course_prev_lesson || undefined;
        if (!previousLesson) return null;
        return {
            name: 'videos-lessonSlug',
            params: {
                lessonSlug: previousLesson.lesson_slug
            },
            query: {
                context: $route.query.context || 'course',
                pathwaySlug: $route.query.pathwaySlug || undefined,
                courseSlug: $route.query.courseSlug || lesson?.lesson_course?.course_slug,
            },
        };
    }

    function parent () {
        if (['pathway', 'course'].includes($route.query.context) && $route.query.courseSlug) {
            return {
                title: 'Course',
                route: {
                    name: 'courses-courseSlug',
                    params: {
                        courseSlug: $route.query.courseSlug,
                    },
                    query: $route.query,
                },
            };
        }
        if ($route.query.context === 'header-search') {
            return {
                title: 'Search',
                route: {
                    name: 'videos',
                    query: {
                        q: $route.query.q,
                    },
                },
            };
        }
        if ($route.query.context === 'videos-search') {
            return ({
                title: 'Search',
                route: {
                    name: 'videos',
                    query: {
                        q: $route.query.q || undefined, // Searched text
                        order: $route.query.order || undefined, // Searched sort order
                        tags: $route.query.tags || undefined, // Searched tags
                        ui: $route.query.ui || undefined, // Collection Mode?
                        title: $route.query.title || undefined, // Custom title

                        lesson_artist: $route.query.lesson_artist || undefined,
                        lesson_category: $route.query.lesson_category || undefined,
                        lesson_decade: $route.query.lesson_decade || undefined,
                        lesson_difficulty: $route.query.lesson_difficulty || undefined,
                        lesson_genre: $route.query.lesson_genre || undefined,
                        lesson_grade: $route.query.lesson_grade || undefined,
                        lesson_instrument: $route.query.lesson_instrument || undefined,
                        lesson_skill: $route.query.lesson_skill || undefined,
                        lesson_type: $route.query.lesson_type || undefined,
                    },
                },
            });
        }
        if (['continue', 'home-songs-recent', 'home-technique-tuneup', 'home-user-lessons'].includes($route.query.context)) {
            return {
                title: 'Home',
                route: {
                    name: 'index',
                },
            };
        }
        if ($route.query.context === 'list-user-lessons') {
            return {
                title: 'My List',
                route: {
                    name: 'my-list',
                },
            };
        }
        if ($route.query.context === 'live-replay') {
            return {
                title: 'Live',
                route: {
                    name: 'live',
                },
            };
        }
        return null;
    }

    return {route, routeSearch, routeNext, routePrevious, parent};
}
